<template>
  <div class="development container space">
    <div class="hidden-xs">
      <div class="common-middle-title">
        <h1>研发体系</h1>
        <h2>快速迭代的用户管理系统+皮肤管理大数据平台</h2>
        <h3>· · ·</h3>
      </div>
      <div class="content">
        <div class="cont-item" v-for="(item, i) in dataList" :key="i">
          <div class="cont-item-left">
            <img :src="item.image" alt="">
          </div>
          <div class="cont-item-right">
            <div class="t1">{{item.title}}</div>
            <div class="t2" v-html="item.desc">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="visible-xs">
      <img src="../../../assets/home/development/small_1.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      dataList: [
        {
          title: 'BFS APP',
          desc: `购买分享精细化运营，<br>  专业的技术研发团队，<br/>为创业者提供便捷的用户体验。`,
          image: require('../../../assets/home/development/dev_1.png')
        },
        {
          title: 'SKIN CODE 皮肤分析',
          desc: `基于大数据管理， <br/>  为消费者评估并搭配最适合的<br/> 肌肤护理方法及产品`,
          image: require('../../../assets/home/development/dev_2.png')
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.development {
  .common-title {
    text-align: center;
  }
  .content {
    display: flex;
    margin-top: 50px;
    .cont-item {
      flex: 1;
      display: flex;
      justify-content: center;
      margin: 0 60px;
      .cont-item-left {
        img {
          width: 170px;
        }
      }
      .cont-item-right {
        margin: 0 20px;
        .t1 {
          font-size: 28px;
          font-weight: bold;
        }
        .t2 {
          color: rgba(51,51,51,.6);
          line-height: 28px;
          font-size: 20px;
          margin-top: 30px;
        }
      }
    }
  }
  .small-wrapper {
    display: none;
  }
}
@media (max-width: 1024px){
  .development {
    .content {
      flex-direction: column;
      .cont-item:last-child {
        margin-top: 30px;
        text-align: right;
        flex-direction: row-reverse;
      }
    }
  }
}

@media (max-width: 768px){
  .development {
    .big-wrapper {
       display: none;
    }
    .small-wrapper {
      display: block;
    }
  }
}
</style>