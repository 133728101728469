<template>
  <div class="about container space" v-if="false">
    <div class="common-title">
      <h1>关于我们</h1>
      <h2>ABOUT US</h2>
    </div>
    <div class="desc">
      <h3>与5000W+ 新中产阶级共同追寻美好生活</h3>
      <h4>以美好生活为出发点，整合细分行业前三的国际高精尖品牌，满足新中产阶级的对产品的苛刻追求</h4>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.about {
  text-align: center;
  .desc {
    h3 {
      margin-top: 40px;
      font-size: 32px;
      font-weight: bold;
    }
    h4 {
      font-size: 22px;
      margin-top: 20px;
    }
  }
}
@media (max-width: 768px){
  .about .desc {
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
    }
  }
}
</style>