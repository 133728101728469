<template>
  <div class="power container space">
    <div class="power-pc hidden-xs">
      <div class="common-middle-title">
        <h1>赋能体系</h1>
        <h2>从0-1进阶式创业赋能+IDI皮肤管理培训体系</h2>
        <h3>· · ·</h3>
      </div>
      <div class="content">
        <div class="power-item">
          <img src="../../../assets/home/power/power_1.png" alt="">
          <div>对平台创业者进行 <br/> 行业入门与创业成长的 系统培训</div>
        </div>
        <div class="power-item">
          <img src="../../../assets/home/power/power_2.png" alt="">
          <div>对平台创业者进行 <br/> 行业销售与深度团队管理 <br/> 的技能培训</div>
        </div>
        <div class="power-item">
          <img src="../../../assets/home/power/power_3.png" alt="">
          <div>牌战略规划与营销 <br/> 全网内容生产力培训</div>
        </div>
        <div class="power-item">
          <img src="../../../assets/home/power/power_4.png" alt="">
          <div>在中国首次引进 <br/> IDI国际皮肤研究院系列 <br/> 权威专业的培训体系</div>
        </div>
      </div>
    </div>
     <div class="power-mobile visible-xs">
      <img src="../../../assets/home/power/small_1.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  .power {
    .power-pc {
      background: url('../../../assets/home/power/bg.png') no-repeat bottom center;
      padding: 100px;
    }
    .common-middle-title {
      h1,
      h2,
      h3 {
        color: #fff;
      }
    }

    .content {
      width: 100%;
      margin: 69px auto 0;
      overflow: hidden;
      .power-item {
        text-align: center;
        color: #fff;
        font-size: 18px;
        width: 25%;
        float: left;
        img {
          width: 154px;
          margin: auto;
          -webkit-transition: all 1s ease-in-out;
          -moz-transition: all 1s ease-in-out;
          -o-transition: all 1s ease-in-out;
          -ms-transition: all 1s ease-in-out;
          transition: all 1s ease-in-out;
        }
        img:hover {
          -webkit-transform: rotateY(360deg);
          -moz-transform: rotateY(360deg);
          -o-transform: rotateY(360deg);
          -ms-transform: rotateY(360deg);
          transform: rotateY(360deg);
        }
        div {
          margin-top: 42px;
          line-height: 30px;
        }
      }
    }

    .power-mobile {
      img {
        width: 100%;
      }
    }
  }
</style>
