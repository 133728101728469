<template>
  <div class="banner">
    <div class="app-info" v-if="false">
      <img class="app-icon " src="../../../assets/home/banner/icon.png" alt="">
      <img class="app-desc" src="../../../assets/home/banner/desc.png" alt="">
      <!-- hidden-xs -->
      <div class="qrcode hidden-xs">
        <img src="../../../assets/home/banner/qrcode_1.png" alt="">
        <img src="../../../assets/home/banner/qrcode_2.png" alt="">
        <img src="../../../assets/home/banner/qrcode_3.png" alt="">
      </div>
      <div class="download visible-xs" @click="download">
        <img src="../../../assets/home/banner/button_small.png" alt="">
      </div>
    </div>
    <img v-if="false" class="mobile" src="../../../assets/home/banner/banner_mobile.png" alt="">
  </div>
</template>
<script>
export default {
  data(){
    return {
      
    }
  },
  methods: {
    download(){
      window.location.href = 'http://download.qingym.cn'
    },
  }
}
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 1080px;
  background-image: url('../../../assets/home/banner/banner_bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  
  .app-info {
    position: absolute;
    top: 20%;
    left: 23%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .app-icon {
      width: 160px;
      mask-border: 10px;
    }
    .app-desc {
      margin-top: 10px;
      width: 300px;
    }
    .qrcode {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      img {
        width: 144px;
        height: 194px ;
        transform: scale(.8);
      }
      img:last-child {
        margin-right: 0;
      }
    }
  }
  .mobile {
    width: auto;
    height: 85%;
    position: absolute;
    left: 56%;
    bottom: 0;
  }
  .download {
    width: 50%;
    margin-top: 80px;
  }
}
@media screen and (max-width:1440px){
  .banner {
    height: 750px;
    .app-info {
      left: 15%;
    }
  }
}
@media screen and (max-width:1024px){
  .banner {
    height: 600px;
    .app-info {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 10%;
      .app-icon {
        width: 30vw;
        max-width: 160px;
      }
      .app-desc {
        width: 50vw;
        max-width: 300px;
      }
    }
    .mobile {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .banner {
    height: 100vh;
    .app-info {
      top: 30%;
    }
  }
}
</style>