<template>
  <div class="home">
    <!-- banner -->
    <banner></banner>
    <!-- 平台简介 -->
    <introduce></introduce>
    <!-- 产品体系 -->
    <product></product> 
    <!-- 品牌介绍 -->
    <brand></brand>
    <!-- 关于 -->
    <about></about>
    <!-- 赋能体系 -->
    <power v-if="false"></power>
    <!-- 研发体系 -->
    <development v-if="false"></development> 
    <!-- 收益体系 -->
    <income v-if="false"></income>  
  </div>
</template>
<script>

import Banner from './children/Banner'
import Introduce from './children/Introduce'
import Brand from './children/Brand'
import About from './children/About'
import Product from './children/Product'
import Power from './children/Power'
import Development from './children/Development'
import Income from './children/Income'
export default {
  components: {
    Banner,
    Introduce,
    Brand,
    About,
    Product,
    Power,
    Development,
    Income
  },
}
</script>
<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  img {
    display: block;
  }
}
</style>