<template>
  <div class="brand container space">
    <div class="common-title">
      <h1>独家品牌简介</h1>
      <h2>BRAND INTRODUCTION</h2>
    </div>
    <div class="brand-wrapper">
      <div class="brand-item" @click="toBrandDetail(item.path)" v-for="(item, i) in brandList" :key="i">
          <img :src="item.image" alt="">
      </div>
    </div>

    <div class="brand-small-wrapper">
      <swiper :options="swiperOption">
        <swiper-slide class="swiper-slide" v-for="(item,index) in brandList" :key="index">
            <img :src="item.image_Mobile" @click="toBrandDetail(item.path)"/>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      brandList: [
        {
          name: 'dermalogica',
          desc: '美国专业院线第一品牌',
          image: require('../../../assets/home/brand/brand_pc_1.png'),
          image_Mobile: require('../../../assets/home/brand/brand_mobile_1.png'),
          path: '/brand/dermalogica'
        },
        {
          name: 'Kate Somervile',
          desc: '好莱坞明星沙龙高端个护品牌',
          image: require('../../../assets/home/brand/brand_pc_2.png'),
          image_Mobile: require('../../../assets/home/brand/brand_mobile_2.png'),
          path: '/brand/kateSomerville'
        },
        {
          name: 'Murad',
          desc: '欧洲高奢贵妇品牌',
          image: require('../../../assets/home/brand/brand_pc_3.png'),
          image_Mobile: require('../../../assets/home/brand/brand_mobile_3.png'),
          path: '/brand/murad'
        },
        {
          name: "JEAN D'ESTREES",
          desc: '法国十大专业院线品牌',
          image: require('../../../assets/home/brand/brand_pc_4.png'),
          image_Mobile: require('../../../assets/home/brand/brand_mobile_4.png'),
          path: '/brand/jde'
        },
        {
          name: "ENDOCARE",
          desc: '欧洲专家级医美修复品牌',
          image: require('../../../assets/home/brand/brand_pc_5.png'),
          image_Mobile: require('../../../assets/home/brand/brand_mobile_5.png'),
        },
        {
          name: 'Bb LABORATORIES',
          desc: '日本高端美容院线护肤品牌',
          image: require('../../../assets/home/brand/brand_pc_6.png'),
          image_Mobile: require('../../../assets/home/brand/brand_mobile_6.png'),
        }
      ],
      swiperOption: {
        slidesPerView: 1.2,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
      }
    }
  },
  methods: {
    toBrandDetail(path){
      this.$router.push({path: path})
    }
  }
}
</script>

<style lang="scss" scoped>
.brand {
  .brand-wrapper {
    overflow: hidden;
    .brand-item {
      width: 32%;
      float: left;
      margin-bottom: 2vw;
      margin-right: 2%;
      margin-top: 60px;
    }
    .brand-item:nth-child(3n) {
      margin-right: 0;
    }
  }
  .brand-small-wrapper {
    display: none;
    margin-top: 30px;
    .swiper-slide {
      position: relative;
      .item-info-btn {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 24px;
        background:rgba(255,255,255,0.3);
        border:1px solid rgba(51,51,51,.3);
        line-height: 34px;
        cursor: pointer;
        transition: all .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 3px;
        img {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }
      }
    }
  }
}
@media (max-width:1024px){
  // .brand {
  //   .brand-wrapper {
  //     .brand-item {
  //       width: 50%;
  //     }
  //   }
  // }
}
@media (max-width:768px){
  .brand {
    margin-top: 30px;
    .brand-wrapper {
      display: none;
    }
    .brand-small-wrapper {
      display: block;
    }
  }
}
</style>
<style lang="scss">
.brand {
  .swiper-slide-active {
    box-shadow:0px 5px 15px 0px rgba(0,0,0,0.1);
    left: -30px;
  }
  .swiper-slide {
    transition: all .1s;
    margin-bottom: 20px;
    img {
      display: block;
    }
  }
  .swiper-slide-prev {
    transform: scale(.9);
    left: -30px;
  }
  .swiper-slide-next {
    transform: scale(.9);
    left: -20px;
  }
}
</style>