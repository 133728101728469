<template>
  <div class="income container space">
  
    <div class="content">
      <div class="common-middle-title">
        <h1>收益体系</h1>
        <h2>多维度收益体系</h2>
        <h3>· · ·</h3>
      </div>
      <div class="desc">成就平台 <span>10000+ </span>个年收入超 <span>100</span> 万的创业者</div>
    </div>
    <div class="other">
      <span>六大创客支持系统</span>
      <span>助力创业更轻松</span>
    </div>
    <img class="img" src="../../../assets/home/income/income_02.png" alt="">
  </div>
</template>

<script>
export default {
  data(){
    return {
      systemList: [
        {
          name: '供应链',
          content: '整合全球优质品牌，<br/> 品牌独家授权， <br/>直采直供正品保障',
          image: require('../../../assets/home/income/income_item_1.png')
        },
        {
          name: '仓储物流',
          content: '遍布全球的云仓储系统，<br/> 一线物流高效承运，<br/> 现已建成三大仓库：<br/> 美国仓、香港仓、金义仓',
          image: require('../../../assets/home/income/income_item_2.png')
        },
        {
          name: '智能IT系统',
          content: ' 高级IT技术团队，<br/> 大数据精准分析，<br/> APP高效赋能',
          image: require('../../../assets/home/income/income_item_3.png')
        },
        {
          name: '客服系统',
          content: '人工+AI双重响应保障，<br/> 提供专业服务',
          image: require('../../../assets/home/income/income_item_4.png')
        },
        {
          name: '科研系统',
          content: '与全球顶级科研机构战略合作，<br/> 大数据结合，<br/> 一对一皮肤诊疗分析',
          image: require('../../../assets/home/income/income_item_5.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.income {
  .content {
    padding: 80px 0;
    background: url('../../../assets/home/income/bg.png');
    .common-middle-title {
      h1,h2,h3 {
        color: #fff;
      }
    }
    .desc {
      color: #fff;
      font-size: 30px;
      text-align: center;
      span {
        font-weight: bold;
        text-align: center;
        font-size: 48px;
      }
    }
  }
  
  .other {
    width: 100%;
    height: 150px;
    line-height: 150px;
    background-color: #F44F57;
    text-align: center;
    color: #fff;
    font-size: 48px;
  }
  .img {
    width: 100%;
    height: auto;
  }
}

@media (max-width:768px){
  .income .content {
    padding: 20px 0;
    .desc {
      font-size: 14px;
      span {
        font-size: 16px;
      }
    }
  }
  .income .other {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }
}

</style>