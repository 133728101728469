<template>
  <div class="product container space" v-if="false">
    <div class="common-middle-title">
      <h1>美寻品牌体系</h1>
      <h2>优选国际新锐品牌，甄选优质好物，追寻美好生活</h2>
      <h3>· · ·</h3>
    </div>
    
    <div class="product-list hidden-xs">
      <img class="product-item" src="../../../assets/home/product/product_01.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_02.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_03.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_04.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_05.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_06.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_07.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_08.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_09.png" alt="">
      <img class="product-item" src="../../../assets/home/product/product_10.png" alt="">
    </div>
    <div class="product-list visible-xs">
      <img class="product-item1" src="../../../assets/home/product/product_01.png" alt="">
      <img class="product-item1" src="../../../assets/home/product/product_02.png" alt="">
      
      <img class="product-item1" src="../../../assets/home/product/product_04.png" alt="">
      <img class="product-item1" src="../../../assets/home/product/product_03.png" alt="">

      <img class="product-item1" src="../../../assets/home/product/product_05.png" alt="">
      <img class="product-item1" src="../../../assets/home/product/product_06.png" alt="">
      
      <img class="product-item1" src="../../../assets/home/product/product_08.png" alt="">
      <img class="product-item1" src="../../../assets/home/product/product_07.png" alt="">
      <img class="product-item1" src="../../../assets/home/product/product_09.png" alt="">
      <img class="product-item1" src="../../../assets/home/product/product_10.png" alt="">
    </div>

  </div>

</template>

<script>
export default {
  data(){
    return {
    }
  },
  mounted(){
    
  },
}
</script>

<style lang="scss">
.product {
  .product-list {
    width: 100%;
    margin-top: 36px;
    overflow: hidden;
  }
  .product-item {
    width: 20%;
    height: auto;
    float: left;
  }
  .product-item1 {
    width: 50%;
    height: auto;
    float: left;
  }
}

</style>